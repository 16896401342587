import MyRentalsRequest from "@/api/request";
import { IAddress } from "@/models/Address";
import { IHousfyAgent } from "@/models/HousfyAgent";
import {
  IAccountingYears,
  IAccountingInAYear,
} from "@/models/IPropertyAccounting";
import { IRentalLeasings } from "@/models/RentalLeasing";
import { IPropertyInfo } from "@/models/PropertyInfo";
import { IPropertyHistoryItems } from "@/models/PropertyHistory";
import { IOwnerPropertySignedContracts } from "@/models/OwnerProperties";

const baseURL = "/user/v1/rentals/my-housfy/property";

export default class PropertyApi {
  private request: MyRentalsRequest;

  constructor(request: MyRentalsRequest) {
    this.request = request;
  }

  async retrieveInfo(uuid: string): Promise<IPropertyInfo> {
    const url = `${baseURL}/${uuid}`;
    return this.request.call<IPropertyInfo>({ method: "GET", url });
  }

  async retrieveAddress(uuid: string): Promise<IAddress> {
    const url = `${baseURL}/${uuid}/address`;
    return this.request.call<IAddress>({ method: "GET", url });
  }

  async retrieveHousfyAgent(uuid: string): Promise<IHousfyAgent> {
    const url = `${baseURL}/${uuid}/housfy-agent`;
    return this.request.call<IHousfyAgent>({ method: "GET", url });
  }

  async retrieveAccountingYears(uuid: string): Promise<IAccountingYears> {
    const url = `${baseURL}/${uuid}/accounting-years`;
    return this.request.call<IAccountingYears>({ method: "GET", url });
  }

  async retrieveAccountingInAYear(
    uuid: string,
    year: number
  ): Promise<IAccountingInAYear> {
    const url = `${baseURL}/${uuid}/accounting?year=${year}`;
    return this.request.call<IAccountingInAYear>({ method: "GET", url });
  }

  async retrieveLeasings(uuid: string): Promise<IRentalLeasings> {
    const url = `${baseURL}/${uuid}/leasings`;
    return this.request.call<IRentalLeasings>({ method: "GET", url });
  }

  async retrievePropertyInfo(uuid: string): Promise<IPropertyInfo> {
    const url = `/user/v1/rentals/my-housfy/property/${uuid}/rented-management`;
    return this.request.call<IPropertyInfo>({ method: "GET", url });
  }

  async retrievePropertyHistory(uuid: string): Promise<IPropertyHistoryItems> {
    const url = `/user/v1/rentals/my-housfy/property/${uuid}/rented-management/history`;
    return this.request.call<IPropertyHistoryItems>({ method: "GET", url });
  }

  async retrievePropertySignedContracts(
    uuid: string
  ): Promise<IOwnerPropertySignedContracts> {
    const url = `/user/v1/rentals/my-housfy/property/${uuid}/property-signed-contracts`;
    return this.request.call<IOwnerPropertySignedContracts>({
      method: "GET",
      url,
    });
  }
}
